<template>
  <section id="email-statistics">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <v-select v-model="selectedEmployees" label="title" multiple :options="allEmployees"
            @input="changeSelectedEmployees" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showGreyhoundOverview" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Number of solved tickets by employee over months
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-number-solved-tickets-employee-months" />
          </h4>
          <b-popover target="popover-number-solved-tickets-employee-months" triggers="hover" placement="bottom">
            <span>The 'Number of Solved Tickets by Employee Over Months' section features a bar chart that illustrates
              the number of solved tickets by each employee over several months. This visual representation helps users
              identify trends and patterns in employee performance. By analyzing the chart, users can easily spot any
              significant changes in the number of solved tickets, such as the impact of workload adjustments or process
              improvements. Additionally, this section provides valuable insights for optimizing team performance and
              enhancing overall customer support efficiency.</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="numberSolvedEmailsByEmployeeChart" :options="numberSolvedEmailsByEmployeeChart"
          style="width: 100%; height: 700px;" />
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Number of solved tickets by employee
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-number-solved-tickets-employee" />
          </h4>
          <b-popover target="popover-number-solved-tickets-employee" triggers="hover" placement="bottom">
            <span>The 'Number of Solved Tickets by Employee' section features a table that lists the number of solved
              tickets by each employee. This tabular representation helps users identify trends and patterns in employee
              performance. By analyzing the table, users can easily spot variations in the number of solved tickets,
              which can provide insights into individual productivity and areas for improvement. Additionally, this
              section offers valuable information for optimizing team performance and enhancing overall customer support
              efficiency.</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="numberSolvedEmailsByEmployeeTable.currentPage" :per-page="9"
          :items="numberSolvedEmailsByEmployeeTable.items" :fields="numberSolvedEmailsByEmployeeTable.fields"
          :sort-by.sync="numberSolvedEmailsByEmployeeTable.sortBy"
          :sort-desc.sync="numberSolvedEmailsByEmployeeTable.sortDesc"
          :sort-direction="numberSolvedEmailsByEmployeeTable.sortDirection"
          :filter="numberSolvedEmailsByEmployeeTable.filter"
          :filter-included-fields="numberSolvedEmailsByEmployeeTable.filterOn">
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="numberSolvedEmailsByEmployeeTable.currentPage"
              :total-rows="numberSolvedEmailsByEmployeeTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Number of solved tickets by team
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-number-solved-tickets-team" />
          </h4>
          <b-popover target="popover-number-solved-tickets-team" triggers="hover" placement="bottom">
            <span>The 'Number of Solved Tickets by Team' section features a bar chart that illustrates the number of
              solved
              tickets by each team, grouped by months. This visual representation helps users identify trends and
              patterns in
              team performance over time. By analyzing the chart, users can easily spot any significant changes in the
              number
              of solved tickets, such as the impact of team strategies or workflow adjustments. Additionally, this
              section
              provides valuable insights for optimizing team collaboration and improving overall customer support
              efficiency.</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="numberSolvedEmailsByTeamChart" :options="numberSolvedEmailsByTeamChart"
          style="width: 100%; height: 700px;" />
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Avg. working time on ticket by employee over months
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-avg-working-time-ticket-employee-months" />
          </h4>
          <b-popover target="popover-avg-working-time-ticket-employee-months" triggers="hover" placement="bottom">
            <span>
              The 'Average Working Time on Ticket by Employee Over Months' section features a bar chart that illustrates
              the
              average working time spent on tickets by each employee over several months. This visual representation
              helps
              users identify trends and patterns in employee efficiency. By analyzing the chart, users can easily spot
              any
              significant changes in working time, such as the impact of training or process improvements. Additionally,
              this
              section provides valuable insights for optimizing workflows and enhancing overall productivity in ticket
              resolution.</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="avgDurationSolvedEmailsByEmployeeChart" :options="avgDurationSolvedEmailsByEmployeeChart"
          style="width: 100%; height: 700px;" />
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Avg. working time on ticket by employee
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-avg-working-time-ticket-employee" />
          </h4>
          <b-popover target="popover-avg-working-time-ticket-employee" triggers="hover" placement="bottom">
            <span>The 'Average Working Time on Ticket by Employee' section features a table that lists the average
              working
              time spent on tickets by each employee. This tabular representation helps users identify trends and
              patterns in
              employee efficiency. By analyzing the table, users can easily spot variations in working time, which can
              provide
              insights into individual performance and areas for improvement. Additionally, this section offers valuable
              information for optimizing workflows and enhancing overall productivity in ticket resolution.</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="avgDurationSolvedEmailsByEmployeeTable.currentPage" :per-page="10"
          :items="avgDurationSolvedEmailsByEmployeeTable.items" :fields="avgDurationSolvedEmailsByEmployeeTable.fields"
          :sort-by.sync="avgDurationSolvedEmailsByEmployeeTable.sortBy"
          :sort-desc.sync="avgDurationSolvedEmailsByEmployeeTable.sortDesc"
          :sort-direction="avgDurationSolvedEmailsByEmployeeTable.sortDirection"
          :filter="avgDurationSolvedEmailsByEmployeeTable.filter"
          :filter-included-fields="avgDurationSolvedEmailsByEmployeeTable.filterOn" :tbody-tr-class="rowClass">
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="avgDurationSolvedEmailsByEmployeeTable.currentPage"
              :total-rows="avgDurationSolvedEmailsByEmployeeTable.totalRows" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Number of solved tickets by topic over months
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-number-solved-tickets-topic-months" />
          </h4>
          <b-popover target="popover-number-solved-tickets-topic-months" triggers="hover" placement="bottom">
            <span>The 'Number of Solved Tickets by Topic Over Months' section features a bar chart that illustrates the
              number
              of solved tickets by topic, grouped by months. This visual representation helps users identify trends and
              patterns in ticket resolution for various topics over time. By analyzing the chart, users can easily spot
              any
              significant changes in the number of solved tickets for each topic, such as the impact of specific
              initiatives
              or common issues. Additionally, this section provides valuable insights for optimizing support strategies
              and
              improving overall customer satisfaction.</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="numberSolvedEmailsByTopicChart" :options="numberSolvedEmailsByTopicChart"
          style="width: 100%; height: 700px;" />
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Number of solved tickets by topic
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-number-solved-tickets-topic" />
          </h4>
          <b-popover target="popover-number-solved-tickets-topic" triggers="hover" placement="bottom">
            <span>The 'Number of Solved Tickets by Topic' section features a table that lists the number of solved
              tickets by
              each topic. This tabular representation helps users identify trends and patterns in ticket resolution
              across
              different topics. By analyzing the table, users can easily spot variations in the number of solved tickets
              for
              each topic, providing insights into common issues and areas that may require additional focus.
              Additionally,
              this section offers valuable information for optimizing support strategies and improving overall customer
              satisfaction.</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="numberSolvedEmailsByTopicTable.currentPage" :per-page="10"
          :items="numberSolvedEmailsByTopicTable.items" :fields="numberSolvedEmailsByTopicTable.fields"
          :sort-by.sync="numberSolvedEmailsByTopicTable.sortBy"
          :sort-desc.sync="numberSolvedEmailsByTopicTable.sortDesc"
          :sort-direction="numberSolvedEmailsByTopicTable.sortDirection" :filter="numberSolvedEmailsByTopicTable.filter"
          :filter-included-fields="numberSolvedEmailsByTopicTable.filterOn">
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="numberSolvedEmailsByTopicTable.currentPage"
              :total-rows="numberSolvedEmailsByTopicTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Number of incoming emails by week
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-number-incoming-emails-week" />
              </h4>
              <b-popover target="popover-number-incoming-emails-week" triggers="hover" placement="bottom">
                <span>The 'Number of Incoming Emails by Week' section features a bar chart that illustrates the number
                  of
                  incoming emails received each week. This visual representation helps users identify trends and
                  patterns in
                  email volume over time. By analyzing the chart, users can easily spot any significant changes in the
                  number
                  of incoming emails, such as the impact of marketing campaigns or seasonal fluctuations. Additionally,
                  this
                  section provides valuable insights for optimizing email management strategies and improving overall
                  customer
                  communication efficiency.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="numberIncomingEmailsByWeekChart" :options="numberIncomingEmailsByWeekChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Number of outgoing emails by week
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-number-outgoing-emails-week" />
              </h4>
              <b-popover target="popover-number-outgoing-emails-week" triggers="hover" placement="bottom">
                <span>The 'Number of Outgoing Emails by Week' section features a bar chart that illustrates the number
                  of
                  outgoing emails sent each week. This visual representation helps users identify trends and patterns in
                  email
                  activity over time. By analyzing the chart, users can easily spot any significant changes in the
                  number of
                  outgoing emails, such as the impact of marketing efforts or changes in communication strategies.
                  Additionally, this section provides valuable insights for optimizing email outreach and improving
                  overall
                  customer engagement.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="numberOutgoingEmailsByWeekChart" :options="numberOutgoingEmailsByWeekChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Number of new emails by employee over months
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-number-new-emails-employee-months" />
              </h4>
              <b-popover target="popover-number-new-emails-employee-months" triggers="hover" placement="bottom">
                <span>The 'Number of New Emails by Employee Over Months' section features a bar chart that illustrates
                  the
                  number of new emails received by each employee over several months. This visual representation helps
                  users
                  identify trends and patterns in email distribution among employees. By analyzing the chart, users can
                  easily
                  spot any significant changes in the number of new emails, such as the impact of workload adjustments
                  or
                  changes in communication flow. Additionally, this section provides valuable insights for optimizing
                  email
                  management and improving overall efficiency in handling incoming communications.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="numberNewEmailsByEmployeeChart" :options="numberNewEmailsByEmployeeChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Number of new emails by employee
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-number-new-emails-employee" />
              </h4>
              <b-popover target="popover-number-new-emails-employee" triggers="hover" placement="bottom">
                <span>
                  The 'Number of New Emails by Employee' section features a table that lists the number of new emails
                  received
                  by each employee. This tabular representation helps users identify trends and patterns in email
                  distribution
                  among employees. By analyzing the table, users can easily spot variations in the number of new emails,
                  which
                  can provide insights into individual workloads and areas for improvement. Additionally, this section
                  offers
                  valuable information for optimizing email management and improving overall efficiency in handling
                  incoming
                  communications.</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative"
              :current-page="numberNewEmailsByEmployeeTable.currentPage" :per-page="9"
              :items="numberNewEmailsByEmployeeTable.items" :fields="numberNewEmailsByEmployeeTable.fields"
              :sort-by.sync="numberNewEmailsByEmployeeTable.sortBy"
              :sort-desc.sync="numberNewEmailsByEmployeeTable.sortDesc"
              :sort-direction="numberNewEmailsByEmployeeTable.sortDirection"
              :filter="numberNewEmailsByEmployeeTable.filter"
              :filter-included-fields="numberNewEmailsByEmployeeTable.filterOn">
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="numberNewEmailsByEmployeeTable.currentPage"
                  :total-rows="numberNewEmailsByEmployeeTable.totalRows" first-number last-number prev-class="prev-item"
                  next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BRow,
  BCol,
  BOverlay,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadioGroup,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';
import 'echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const sixMonthsAgo = new Date();
sixMonthsAgo.setMonth(currentDate.getMonth() - 5);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    vSelect,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      showGreyhoundOverview: true,
      oldDateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      selectedEmployees: [],
      allEmployees: [],
      employeeTeam: [],
      queryParams: {},
      numberSolvedEmailsByEmployeeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberSolvedEmailsByTeamChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberSolvedEmailsByTopicChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgDurationSolvedEmailsByEmployeeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let totalSeconds = Math.floor(param.value);
              const label = param.seriesName;

              const days = Math.floor(totalSeconds / (3600 * 24));
              const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
              const minutes = Math.floor((totalSeconds % 3600) / 60);

              if (days > 0) {
                result += `${param.marker} ${label}: ${days} days ${hours} hours ${minutes} minutes<br/>`;
              } else if (hours > 0) {
                result += `${param.marker} ${label}: ${hours} hours ${minutes} minutes<br/>`;
              } else {
                result += `${param.marker} ${label}: ${minutes} minutes<br/>`;
              }
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberSolvedEmailsByEmployeeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created_date',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      avgDurationSolvedEmailsByEmployeeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'avg_duration',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          {
            key: 'username',
            label: 'employee',
            sortable: true,
          },
          {
            key: 'avg_duration',
            label: 'avg. working time',
            sortable: true,
            formatter: value => {
              let totalSeconds = Math.floor(value);

              const days = Math.floor(totalSeconds / (3600 * 24));
              const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
              const minutes = Math.floor((totalSeconds % 3600) / 60);

              if (days > 0) {
                return `${days} days ${hours} hours ${minutes} minutes`;
              } else if (hours > 0) {
                return `${hours} hours ${minutes} minutes`;
              } else {
                return `${minutes} minutes`;
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      numberSolvedEmailsByTopicTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total_count',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          {
            key: 'topic_name',
            label: 'topic',
            sortable: true,
          },
          {
            key: 'total_count',
            label: 'total count',
            sortable: true,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      numberIncomingEmailsByWeekChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberOutgoingEmailsByWeekChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberNewEmailsByEmployeeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberNewEmailsByEmployeeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created_date',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          {
            key: 'username',
            label: 'username',
            sortable: true,
          },
          {
            key: 'total_count',
            label: 'total count',
            sortable: true,
          },
          {
            key: 'created_date',
            label: 'date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getEmployeeNames();
      await this.getEmployeeTeam();
      this.queryParams.selected_employees = this.selectedEmployees;
      await this.getGreyhoundOverview();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    rowClass(item) {
      const totalSeconds = Math.floor(item.avg_duration);
      return totalSeconds >= 172800 ? 'table-danger' : '';
    },
    updateGreyhoundCharts(data) {
      const dataSolvedEmailsByUser = data[0].data.results;
      const dataSolvedEmailsByTopic = data[1].data.results;

      // Solved emails by user
      let employeeNames = dataSolvedEmailsByUser.map(item => item.username);
      let dates = dataSolvedEmailsByUser.map(item => `${new Date(item.created_date).getMonth() + 1}.${new Date(item.created_date).getFullYear()}`);
      employeeNames = [...new Set(employeeNames)];
      dates = [...new Set(dates)];

      const numberSolvedEmailsByEmployeeChartDatasets = [];
      const numberSolvedEmailsByTeamChartDatasets = [];
      const avgDurationSolvedEmailsByEmployeeChartDatasets = [];

      // Fill data for employees
      employeeNames.forEach((employee, i) => {
        const numberSolvedEmails = new Array(dates.length).fill(0);
        const avgDurationSolvedEmails = new Array(dates.length).fill(0);

        for (let j = 0; j < dates.length; j++) {
          for (const email of dataSolvedEmailsByUser) {
            const dateObj = new Date(email.created_date);
            const d = `${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

            if (employee === email.username && dates[j] === d) {
              numberSolvedEmails[j] = email.total_count;
              avgDurationSolvedEmails[j] = email.avg_duration;
              break;
            }
          }
        }

        numberSolvedEmailsByEmployeeChartDatasets.push({
          name: employee,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? employee : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: numberSolvedEmails,
        });
        avgDurationSolvedEmailsByEmployeeChartDatasets.push({
          name: employee,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? employee : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: avgDurationSolvedEmails,
        });
      });

      // Create team map from employee_team
      const employee_team = this.employeeTeam;
      const teamMap = {};
      employee_team.forEach(({ full_name, team__name }) => {
        if (!teamMap[team__name]) {
          teamMap[team__name] = [];
        }
        teamMap[team__name].push(full_name);
      });

      // Fill data for teams
      Object.keys(teamMap).forEach((team, index) => {
        const teamMembers = teamMap[team];
        const numberSolvedEmails = new Array(dates.length).fill(0);

        for (let j = 0; j < dates.length; j++) {
          teamMembers.forEach(member => {
            for (const email of dataSolvedEmailsByUser) {
              const dateObj = new Date(email.created_date);
              const d = `${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

              if (member === email.username && dates[j] === d) {
                numberSolvedEmails[j] += email.total_count;
                break;
              }
            }
          });
        }

        numberSolvedEmailsByTeamChartDatasets.push({
          name: team,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: numberSolvedEmails,
        });
      });

      this.numberSolvedEmailsByEmployeeChart.xAxis.data = dates;
      this.numberSolvedEmailsByEmployeeChart.series = numberSolvedEmailsByEmployeeChartDatasets;

      // Helper function to format the month key
      const formatMonthKey = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}.${year}`;
      };

      // Grouping by username
      const groupedData = dataSolvedEmailsByUser.reduce((acc, item) => {
        const monthKey = formatMonthKey(item.created_date);

        if (!acc[item.username]) {
          acc[item.username] = { username: item.username };
        }

        acc[item.username][monthKey] = item.total_count;

        return acc;
      }, {});
      // Converting grouped data to the desired array format
      const dataSolvedEmailsByUserNew = Object.values(groupedData).map(item => ({
        username: item.username,
        ...item
      }));
      const uniqueKeys = Array.from(new Set(dataSolvedEmailsByUserNew.flatMap(item => Object.keys(item))));
      const fields = uniqueKeys.map(key => ({
        key: key,
        label: key,
        sortable: true
      }));
      this.numberSolvedEmailsByEmployeeTable.fields = fields;
      this.numberSolvedEmailsByEmployeeTable.items = dataSolvedEmailsByUserNew;
      this.numberSolvedEmailsByEmployeeTable.totalRows = dataSolvedEmailsByUserNew.length * 2;

      this.numberSolvedEmailsByTeamChart.xAxis.data = dates;
      this.numberSolvedEmailsByTeamChart.series = numberSolvedEmailsByTeamChartDatasets;

      this.avgDurationSolvedEmailsByEmployeeChart.xAxis.data = dates;
      this.avgDurationSolvedEmailsByEmployeeChart.series = avgDurationSolvedEmailsByEmployeeChartDatasets;

      const avgDurationSolvedEmailsByEmployeeTableData = data[2].data.results;
      this.avgDurationSolvedEmailsByEmployeeTable.items = avgDurationSolvedEmailsByEmployeeTableData;
      this.avgDurationSolvedEmailsByEmployeeTable.totalRows = avgDurationSolvedEmailsByEmployeeTableData.length * 2;

      // Solved emails by topic
      let topicNames = dataSolvedEmailsByTopic.map(item => item.topic_name);
      dates = dataSolvedEmailsByTopic.map(item => `${new Date(item.created_date).getMonth() + 1}.${new Date(item.created_date).getFullYear()}`);
      topicNames = [...new Set(topicNames)];
      dates = [...new Set(dates)];

      const numberSolvedEmailsByTopicChartDatasets = [];
      // Fill data for topics
      topicNames.forEach((topic, i) => {
        const numberSolvedEmails = new Array(dates.length).fill(0);

        for (let j = 0; j < dates.length; j++) {
          for (const email of dataSolvedEmailsByTopic) {
            const dateObj = new Date(email.created_date);
            const d = `${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

            if (topic === email.topic_name && dates[j] === d) {
              numberSolvedEmails[j] = email.total_count;
              break;
            }
          }
        }

        numberSolvedEmailsByTopicChartDatasets.push({
          name: topic,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? topic : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: numberSolvedEmails,
        });
      });

      this.numberSolvedEmailsByTopicChart.xAxis.data = dates;
      this.numberSolvedEmailsByTopicChart.series = numberSolvedEmailsByTopicChartDatasets;

      const numberSolvedEmailsByTopicTableData = data[3].data.results;
      this.numberSolvedEmailsByTopicTable.items = numberSolvedEmailsByTopicTableData;
      this.numberSolvedEmailsByTopicTable.totalRows = numberSolvedEmailsByTopicTableData.length * 2;

      // Incoming emails by week
      const dataIncomingEmailsByWeek = data[4].data.results;
      this.numberIncomingEmailsByWeekChart.xAxis.data = dataIncomingEmailsByWeek.map(item => item.week);
      this.numberIncomingEmailsByWeekChart.series = {
        name: 'Total count',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: dataIncomingEmailsByWeek.map(item => item.total_count),
      };

      // Incoming emails by week
      const dataOutgoingEmailsByWeek = data[5].data.results;
      this.numberOutgoingEmailsByWeekChart.xAxis.data = dataOutgoingEmailsByWeek.map(item => item.week);
      this.numberOutgoingEmailsByWeekChart.series = {
        name: 'Total count',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: dataOutgoingEmailsByWeek.map(item => item.total_count),
      };

      // New emails by user and month
      const dataNewEmailsByUser = data[6].data.results;
      employeeNames = dataNewEmailsByUser.map(item => item.username);
      dates = dataNewEmailsByUser.map(item => `${new Date(item.created_date).getMonth() + 1}.${new Date(item.created_date).getFullYear()}`);
      employeeNames = [...new Set(employeeNames)];
      dates = [...new Set(dates)];

      const numberNewEmailsByEmployeeChartDatasets = [];
      // Fill data for employees
      employeeNames.forEach((employee, i) => {
        const numberNewEmails = new Array(dates.length).fill(0);

        for (let j = 0; j < dates.length; j++) {
          for (const email of dataNewEmailsByUser) {
            const dateObj = new Date(email.created_date);
            const d = `${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

            if (employee === email.username && dates[j] === d) {
              numberNewEmails[j] = email.total_count;
              break;
            }
          }
        }

        numberNewEmailsByEmployeeChartDatasets.push({
          name: employee,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? employee : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: numberNewEmails,
        });
      });

      this.numberNewEmailsByEmployeeChart.xAxis.data = dates;
      this.numberNewEmailsByEmployeeChart.series = numberNewEmailsByEmployeeChartDatasets;

      this.numberNewEmailsByEmployeeTable.items = dataNewEmailsByUser;
      this.numberNewEmailsByEmployeeTable.totalRows = dataNewEmailsByUser.length * 2;
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getEmployeeNames() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/jtl-support-employee-names/`, {});
        this.allEmployees = response.data.results
          .filter(item => item.full_name !== "Webshop Import")
          .map(item => item.full_name);
        this.selectedEmployees = response.data.results
          .filter(employee => employee.full_name !== "Hendirk Homann")
          .filter(employee => employee.full_name !== "Lukas Homann")
          .filter(employee => employee.full_name !== "Angela Melzer")
          .filter(item => item.full_name !== "Webshop Import")
          .map(employee => employee.full_name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getGreyhoundOverview() {
      this.showGreyhoundOverview = true;
      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/greyhound-solved-email-by-user-and-date/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/greyhound-solved-email-by-topic-and-date/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/greyhound-solved-email-by-user/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/greyhound-solved-email-by-topic/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/greyhound-incoming-email-by-week/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/greyhound-outgoing-email-by-week/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/greyhound-new-email-by-user-and-date/`, this.queryParams),
        ]);
        this.updateGreyhoundCharts(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showGreyhoundOverview = false;
      }
    },
    async getEmployeeTeam() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/employee-team/`, {});
        this.employeeTeam = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        await this.getGreyhoundOverview();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.numberSolvedEmailsByEmployeeChart.refresh();
          this.$refs.numberSolvedEmailsByTeamChart.refresh();
          this.$refs.avgDurationSolvedEmailsByEmployeeChart.refresh();
          this.$refs.numberSolvedEmailsByTopicChart.refresh();
          this.$refs.numberIncomingEmailsByWeekChart.refresh();
          this.$refs.numberOutgoingEmailsByWeekChart.refresh();
          this.$refs.numberNewEmailsByEmployeeChart.refresh();
        });
      }
    },
    async changeSelectedEmployees() {
      this.queryParams.selected_employees = this.selectedEmployees;
      await this.getGreyhoundOverview();

      this.$nextTick(() => {
        this.$refs.numberSolvedEmailsByEmployeeChart.refresh();
        this.$refs.numberSolvedEmailsByTeamChart.refresh();
        this.$refs.avgDurationSolvedEmailsByEmployeeChart.refresh();
        this.$refs.numberSolvedEmailsByTopicChart.refresh();
        this.$refs.numberIncomingEmailsByWeekChart.refresh();
        this.$refs.numberOutgoingEmailsByWeekChart.refresh();
        this.$refs.numberNewEmailsByEmployeeChart.refresh();
      });
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>